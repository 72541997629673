// Sass
import 'core-js/stable/global-this';
import './Sass/includes/Components/modules/C01Notification.sass'
import './Sass/includes/Components/modules/C01Login.sass'


import C01NotificationPeopleOrg from './Components/Organisms/Personas/C01Notification'

function App() {
  return (
    <div className="App">
      {/* <ModulosPeopleContainer /> */}
      <C01NotificationPeopleOrg
        classProps={'onlyC01Noty'}
      />
    </div>
  );
}

export default App;
