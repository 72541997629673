import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
// import jQuery from 'jquery'
import $ from 'jquery'
import PropTypes from 'prop-types'

import WOW from 'wow.js'
import 'animate.css'


// const MESES = [
//     "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
//     "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
//   ];

let inactivityTimeout = null;
let messageIndex = 0;
function C01Notification(props) {
    const {
        // title,
        // description,
        // price,
        // validity,
        // goTo,
        // urlGoTo,
        // targetGoTo,
        classProps
    } = props

    const passwordRef = useRef(false)
    const eyeIcoRef = useRef()

    const [dataConsultaSaldo, setDataConsultaSaldo] = useState([])
    const [randomItem, setRandomItem] = useState([])
    const [username, setUserName] = useState('')
    const [password, setPassWord] = useState('')
    const [urlServicioWeb, setUrlServicioWeb] = useState(localStorage.getItem('url_servicio_web'))
    
    const [notificacionHidden, setNotificacionHidden] = useState('')
    const [displayDefaultNoti, setDisplayDefaultNoti] = useState(localStorage.getItem('notificacion_default_login_hidden'))
    const [title, setTitle] = useState(localStorage.getItem('notificacion_default_login_title'))
    const [description, setDescription] = useState(localStorage.getItem('notificacion_default_login_description'))
    const [price, setPrice] = useState(localStorage.getItem('notificacion_default_login_price'))
    const [validity, setValidity] = useState(localStorage.getItem('notificacion_default_login_validity'))
    const [goTo, setGoTo] = useState(localStorage.getItem('notificacion_default_login_goTo'))
    const [urlGoTo, setUrlGoTo] = useState(localStorage.getItem('notificacion_default_login_urlGoTo'))
    const [targetGoTo, setTargetGoTo] = useState(localStorage.getItem('notificacion_default_login_targetGoTo'))
    // const [preReload, setPreReload] = useState(true)

    const getValuesDefault = async()=>{
        urlServicioWeb === '' ? setUrlServicioWeb(localStorage.getItem('url_servicio_web')) : null

        notificacionHidden === '' ? setNotificacionHidden(localStorage.getItem('notificacion_hidden')) : 'true'

        displayDefaultNoti === '' ? setDisplayDefaultNoti(localStorage.getItem('notificacion_default_login_hidden')) : 'true'
        title       === '' ? setTitle(localStorage.getItem('notificacion_default_login_title'))                : null
        description === '' ? setDescription(localStorage.getItem('notificacion_default_login_description'))    : null
        price       === '' ? setPrice(localStorage.getItem('notificacion_default_login_price'))                : null
        validity    === '' ? setValidity(localStorage.getItem('notificacion_default_login_validity'))          : null
        goTo        === '' ? setGoTo(localStorage.getItem('notificacion_default_login_goTo'))                  : null
        urlGoTo     === '' ? setUrlGoTo(localStorage.getItem('notificacion_default_login_urlGoTo'))            : null
        targetGoTo  === '' ? setTargetGoTo(localStorage.getItem('notificacion_default_login_targetGoTo'))      : null

    }
    useEffect(() =>{
        getValuesDefault();
        // eslint-disable-next-line
    }, [])

  const inactivityThreshold = 60000; // 3 seconds (adjust as needed)
  function updateCookieExpiration(cookieName, cookieValue) {
    var currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 1);
  
    var newExpirationDate = currentDate.toUTCString();
    document.cookie = `${cookieName}=${cookieValue}; expires=${newExpirationDate}; path=/; domain=${window.location.host.replace(":3000","").replace("www.","")}; secure; samesite=None`;
  }

  const resetInactivityTimer = () => {
    console.log("resetTimeout")
      clearTimeout(inactivityTimeout);
      const newTimeout = setTimeout(() => {
          console.log("actualizo")
          location.reload()
        }, inactivityThreshold);
        inactivityTimeout = newTimeout;
        console.log("refresca y me muestro", newTimeout, inactivityTimeout)

    // updateCookieExpiration("DATA_USER", getCookie("DATA_USER"));
    // updateCookieExpiration("authorization_sso", getCookie("authorization_sso"));
    // updateCookieExpiration("id_token", getCookie("id_token"));
    // updateCookieExpiration("authorization_code", getCookie("authorization_code"));

  };

  useEffect(() => {
    
    // Listen for mousemove and keydown events
    // const handleActivity = () => resetInactivityTimer();
    // document.addEventListener('mousemove', handleActivity);
    // document.addEventListener('keydown', handleActivity);

    // window.addEventListener('touchmove', handleActivity);

    // Initial setup to start the timer
    //resetInactivityTimer();

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
      window.removeEventListener('touchmove', resetInactivityTimer);
      clearTimeout(inactivityTimeout);
    };
  }, []);

    function validForm(formName, viewAlert = false) {
        let newform = $(`form[name="${formName}"]`)[0]
        console.log("each newform---->>>", newform)
        if (newform) {
            for (let element of newform) {
                if (element.name) {
                    // console.log("element--->", element.name);
                    element.setAttribute("title", '');
                    element.addEventListener('invalid', (e) => eventSubmit(e, viewAlert), false);
                    element.addEventListener('keypress', () => removeElement(element.name));
                    element.addEventListener('change', () => removeElement(element.name));
                }
            }
        }
    }
    //Input message validation
    const INPUT_MESSAGE_REQUERID = 'Complete este campo'
    const EMAIL_MESSAGE_FORMAT = 'El formato de correo es inválido'
    const MESSAGE_TOO_SHORT = 'El mínimo de caracteres debe ser $value'
    const PATTERN_MESSAGE = 'Ingrese en formato de'//'Utilice un formato que coincida con el solicitado'
    const MESSAGE_TOO_LONG = 'El máximo de caracteres para el campo es de $value'
    const MESSAGE_RANGE_MAX = 'El rango máximo para el campo es de $value'
    const MESSAGE_RANGE_MIN = 'El rango minimo para el campo es de $value'
    const MESSAGE_STEP = 'El valor ingresado debe de ser múltiplo del $value'
    const MESSAGE_INVALID = 'El valor ingresado no es válido'
    const MESSAGE_TYPE_INVALID = 'El tipo de dato no es válido'
    const MESSAGE_BAD_INPUT = 'Valor no válido o no puede ser convertido'
    //   const PHONE_NUMBER_CONTACT_INVALID = 'Teléfono de contacto inválido'
    //   const PHONE_NUMBER_INVALID = 'Teléfono inválido'
    //   const NUMBER_CARD_INVALID = 'Número de tarjeta inválido'
    //   const SELECT_ACCOUNT = 'Agregue una cuenta'
    //   const SELECT_SERVICE_NUMBER = 'Agregue un número de servicio'

    function eventSubmit(e, viewAlert) {
        e.preventDefault()

        // if (viewAlert) openCloseAlertForm(true)

        const elementoAnterior = e.target
        elementoAnterior.setAttribute("title", '');

        const nameError = 'error' + elementoAnterior.name
        removeElement(elementoAnterior.name)
        let nuevoElemento = document.createElement('span');
        nuevoElemento.setAttribute("name", nameError);
        nuevoElemento.className = 'errorField light'


        if (elementoAnterior.validity.badInput) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_BAD_INPUT));
            // elementoAnterior.setAttribute("title", MESSAGE_BAD_INPUT);
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.valueMissing) {
            nuevoElemento.appendChild(document.createTextNode(INPUT_MESSAGE_REQUERID));
            // elementoAnterior.setAttribute("title", INPUT_MESSAGE_REQUERID);
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.typeMismatch) {
            if (elementoAnterior.type === "email") {
                nuevoElemento.appendChild(document.createTextNode(EMAIL_MESSAGE_FORMAT));
                // elementoAnterior.setAttribute("title", EMAIL_MESSAGE_FORMAT);
                elementoAnterior.setAttribute("title", '');
                elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
            } else {
                nuevoElemento.appendChild(document.createTextNode(MESSAGE_TYPE_INVALID));
                // elementoAnterior.setAttribute("title", MESSAGE_TYPE_INVALID);
                elementoAnterior.setAttribute("title", '');
                elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
            }
        } else if (elementoAnterior.validity.patternMismatch) {
            const formato = elementoAnterior.dataset.pattern ? elementoAnterior.dataset.pattern : ''
            nuevoElemento.appendChild(document.createTextNode(PATTERN_MESSAGE + formato));
            // elementoAnterior.setAttribute("title", PATTERN_MESSAGE + formato);
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.tooLong) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_TOO_LONG.replace("$value", elementoAnterior.maxLength)));
            // elementoAnterior.setAttribute("title", MESSAGE_TOO_LONG.replace("$value", elementoAnterior.maxLength));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.tooShort) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_TOO_SHORT.replace("$value", elementoAnterior.minLength)));
            // elementoAnterior.setAttribute("title", MESSAGE_TOO_SHORT.replace("$value", elementoAnterior.minLength));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.rangeOverflow) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_RANGE_MAX.replace("$value", elementoAnterior.max)));
            // elementoAnterior.setAttribute("title", MESSAGE_RANGE_MAX.replace("$value", elementoAnterior.max));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.rangeUnderflow) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_RANGE_MIN.replace("$value", elementoAnterior.min)));
            // elementoAnterior.setAttribute("title", MESSAGE_RANGE_MIN.replace("$value", elementoAnterior.min));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else if (elementoAnterior.validity.stepMismatch) {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_STEP));
            // elementoAnterior.setAttribute("title", MESSAGE_STEP.replace("$value", elementoAnterior.step));
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        } else {
            nuevoElemento.appendChild(document.createTextNode(MESSAGE_INVALID));
            // elementoAnterior.setAttribute("title", MESSAGE_INVALID);
            elementoAnterior.setAttribute("title", '');
            elementoAnterior.parentNode.insertBefore(nuevoElemento, elementoAnterior.nextSibling);
        }
        elementoAnterior.focus()
        // elementoAnterior.type = ""
        // console.log("elementoAnterior---->", e, elementoAnterior.name, elementoAnterior.validity)

    }
    function removeElement(name) {
        let nameError = 'error' + name
        let node = document.getElementsByName(nameError)[0]
        if (node) {
            let element = document.getElementsByName(name)[0]
            element.title = ""
            node.remove();
        }
    }
    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) !== -1) return c.substring(name.length, c.length);
        }
        return "";
    }

    useEffect(() => {
        let authorization_sso = getCookie("authorization_sso");
        // let authorization_sso = sessionStorage.getItem('authorization_sso');
        let dataUser = getCookie("DATA_USER") == "" ? null: decodeURIComponent(getCookie("DATA_USER"));
        console.log("dataaaUser", dataUser);
        let DATA_USER = JSON.parse(dataUser);
        // let DATA_USER = JSON.parse(sessionStorage.getItem("DATA_USER"));
        console.log("authorization_sso: " + authorization_sso);
        console.log("DATA_USER: " + DATA_USER);
        if (authorization_sso && DATA_USER?.sub) {
            console.log("entroooIF", authorization_sso, " ", DATA_USER?.sub)
            $("#c01LoginFloatDiv").hide()
            getUserInfo(authorization_sso)
        }else if(authorization_sso){
            getUserInfo()
        }else{
            localStorage.removeItem("authorization_sso")
            localStorage.removeItem("id_token")
            localStorage.removeItem("authorization_code")
            sessionStorage.removeItem("DATA_USER")

            document.removeEventListener('mousemove', resetInactivityTimer);
            document.removeEventListener('keydown', resetInactivityTimer);
            window.removeEventListener('touchmove', resetInactivityTimer);
            clearTimeout(inactivityTimeout);
        }
        // eslint-disable-next-line

        // let DATA_USER = sessionStorage.getItem("DATA_USER");

        if (DATA_USER) {
            console.log("entro useEffect datauser", DATA_USER)
            hideAndReplaceComponets(DATA_USER)
            console.log("hideAndReplaceComponets if")
        }
        var cookiename = getCookie('LoginUser');
        if (!cookiename) {
            console.log("cookiename: ", cookiename)
            clearSessionStorage()
            // $("#welcomeUsername").remove()
            $("#c01LoginFormReactPR").show()
            $("#c01LoginFloatDiv").show()
            $("#contentNameUser").text("")
            $("#bloqueUser").hide()
        } else {
            WriteCookie()
        }
        new WOW().init();
        let Consulta_de_Saldo = localStorage.getItem('Consulta_de_Saldo') ? JSON.parse(localStorage.getItem('Consulta_de_Saldo')) : '';
        if (Consulta_de_Saldo !== '') {
            console.log(" Consulta_de_Saldo ********************************+", Consulta_de_Saldo)
            // getDataConsultaSaldo(Consulta_de_Saldo)
            $("#c01LoginFormReactPR").hide()
        }
        validForm("formLoginReactPR");
    },[])

    useEffect(() => {
        
        // eslint-disable-next-line
    }, [])


    function randomItemGenerator() {

        let message = dataConsultaSaldo[messageIndex];
        if(messageIndex == dataConsultaSaldo.length) {
            messageIndex = 0;
        }

        if(messageIndex < dataConsultaSaldo.length - 1) {
            console.log("entro", messageIndex)
            messageIndex += 1;
        }else{
            messageIndex = 0;
        }
        
        return message;
    }
    console.log("se actualizo para obtener las notificaciones en produccion")

    useEffect(() => {
        if(dataConsultaSaldo?.length) {
            // let randomItemGeneratorVal = randomItemGenerator();
            // setRandomItem(randomItemGeneratorVal)
            let randomItemGeneratorVal = randomItemGenerator();
                    setRandomItem(randomItemGeneratorVal)
            setInterval(() => {
                if (dataConsultaSaldo?.length) {
                    console.log(dataConsultaSaldo, "hola")
                    let randomItemGeneratorVal = randomItemGenerator();
                    setRandomItem(randomItemGeneratorVal)
                }
            }, 4000)
        }
        // eslint-disable-next-line
    }, [dataConsultaSaldo], [])


    const handleShowPass = () => {
        const typeEye = passwordRef.current.getAttribute('type' )=== 'password' ? 'ico-eye-off' : 'ico-eye'
        eyeIcoRef.current.setAttribute('class', typeEye);

        const type = passwordRef.current.getAttribute('type') === 'password' ? 'text' : 'password';
        passwordRef.current.setAttribute('type', type);
    }

    async function getDataConsultaSaldo(Consulta_de_Saldo = '') {
        console.log(" dataConsultaSaldo ********************************+", Consulta_de_Saldo.length)
        if (Consulta_de_Saldo?.length) {
            let ConsultaJson = Consulta_de_Saldo
            // await setDataConsultaSaldo(ConsultaJson)
            // await setRandomItem(ConsultaJson[0])
            // console.log("init RandomItem", ConsultaJson[0])
        }
    }
    function onSubmitLoginAppReact(e) {
    document.addEventListener('mousemove', () => resetInactivityTimer());
    document.addEventListener('keydown', () => resetInactivityTimer());

    window.addEventListener('touchmove', () => resetInactivityTimer());
        e.preventDefault();
        clearStatusErrorAppReact()
        let inputusername = $("input[name='username'")
        let inputpassword = $("input[name='password'")
        let btnLogin = $("#btnLogin")
        var error = false;

        if (error === false) {
            inputusername.prop("disabled", true)
            inputpassword.prop("disabled", true)
            btnLogin.prop("disabled", true)
            sendLoginAppReact(inputusername, inputpassword, btnLogin);
        }
    }
    async function getAssociatedAccounts(DATA_USER){
        $("#c01LoginFloatDiv").hide()
        console.log("user.loginName: ", DATA_USER.loginname)
        let url_servicio_web = urlServicioWeb
        const Accounts = {
            method: 'GET',
            url: url_servicio_web+"AssociatedAccountsService?user.loginName="+DATA_USER.loginname,
            // data: {},
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
        };
        let responseAccounts = await axios(Accounts)
        console.log("response- getAssociatedAccounts------------------------->", responseAccounts?.data );
        let dataPrivilege = responseAccounts?.data.length && responseAccounts?.data.indexOf('error_description') < 0? responseAccounts?.data[0] : null

        let dataArrayConsultaAll = [], dataArray = null
        let dataArrayConsultaPending = [], dataArrayConsultaPaid = []
        
        if (dataPrivilege){
            let newArrayAccounts = dataPrivilege.privilege.filter(
                item => (item?.manageableAsset?.partyProfile?.id === "2" || item?.manageableAsset?.partyProfile?.id === "3")
                )
            console.log("newArrayAccounts", newArrayAccounts)
            if(newArrayAccounts.length){
                
                for( let i = 0; i < newArrayAccounts.length; i++){
                    console.log("newArrayAccounts for:", newArrayAccounts[i])
                    dataArray = await WriteConsulta_de_Saldo(DATA_USER, newArrayAccounts[i]?.manageableAsset)

                    var today = new Date();
                    
                    dataArray.map((item, index) => {
                        var paymentDueDate = item?.paymentDueDate ? new Date(item.paymentDueDate) : new Date();
                        console.log("newArrayAccounts for WriteConsulta_de_Saldo:", item?.state, today, paymentDueDate,  item)

                        if (item.state === "Paid")  dataArrayConsultaPaid.push(item) 
                        
                        if ((item.state === "Pending" || item.state === "Overdue") && today >= paymentDueDate)  dataArrayConsultaPending.push(item) 
                    })
                }
            }else{
                console.log("Error getting Postpago: manageableAsset?.partyProfile?.id ", dataPrivilege?.privilege)
            }
        }
        if(dataArrayConsultaPending?.length){
            if (dataArrayConsultaPending?.length > 1 ){
                let newTitle = DATA_USER?.given_name ? DATA_USER?.given_name+", ¡Una de tus facturas está vencida!" : "¡Una de tus facturas está vencida!"
                dataArrayConsultaAll.push({
                    title: newTitle,
                    description: "Paga tus facturas a unos cuantos clicks y sin salir de casa. ¡Es fácil y sencillo!",
                    // price: dataInvoiceDetailCurrentPeriod?.remainingAmount ? dataInvoiceDetailCurrentPeriod.remainingAmount.value : '',
                    validity: true,
                    goTo: 'Pagar aquí',
                    urlGoTo: 'https://www.miclaro.com.gt/facturas',
                    toShow: true,
                    // toDay: date2.toLocaleString(),
                })
            }else{
                let newTitle = DATA_USER?.given_name ? DATA_USER?.given_name+", ¡Tu factura está vencida!" : "¡Tu factura está vencida!"
                dataArrayConsultaAll.push({
                    title: newTitle,
                    description: "Paga tu factura a unos cuantos clicks y sin salir de casa. ¡Es fácil y sencillo!",
                    // price: dataInvoiceDetailCurrentPeriod?.remainingAmount ? dataInvoiceDetailCurrentPeriod.remainingAmount.value : '',
                    validity: true,
                    goTo: 'Pagar aquí',
                    urlGoTo: 'https://gt.mipagoclaro.com/#/',
                    toShow: true,
                    // toDay: date2.toLocaleString(),
                })
            }
        }
        if(dataArrayConsultaAll.length){
            localStorage.setItem('Consulta_de_Saldo', JSON.stringify(dataArrayConsultaAll))
            getDataConsultaSaldo(dataArrayConsultaAll)
            $("#c01LoginFloatDiv").show()
        }
        console.log("dataArrayConsultaAll:", dataArrayConsultaAll, dataArrayConsultaPaid, dataArrayConsultaPending, dataArrayConsultaPending?.length)
        
    }
    
    async function WriteConsulta_de_Saldo(DATA_USER, Accounts) {
        // $("#c01LoginFloatDiv").hide()
        let relatedParty = Accounts?.id
        console.log("WriteConsulta_de_Saldo:", Accounts)
        let url_servicio_web = urlServicioWeb
        const Notificaciones = {
            method: 'GET',
            url: url_servicio_web+"CustomerBillService?relatedParty.id="+relatedParty,
            // data: {},
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
        };

        let responseNoti = await axios(Notificaciones)
        // let Consulta_de_SaldoAll = ''
        console.log("responseNoti- getNotificaciones------------------------->", responseNoti?.data);
        let dataRespose = responseNoti?.data
        
        // let dataArrayConsultaAll= []

        // if (responseNoti.data?.ConsumptionService) {
        //     // let dataFilterbucket = responseNoti.data.ConsumptionService.bucket
        //     // dataArrayConsultaAll = dataFilterbucket.map((item, index) => (
        //     //     {
        //     //         title: item?.name ? item.name : '',
        //     //         description: item?.bucketBalance[0]?.remainingValueLabel ? item.bucketBalance[0].remainingValueLabel : '',
        //     //         price: item?.price ? item.price : '',
        //     //         validity: item?.bucketBalance[0]?.validFor ? item.bucketBalance[0].validFor?.endDateTime : '',
        //     //         goTo: item?.goTo ? item?.goTo : '',
        //     //         toShow: new Date(item.bucketBalance[0].validFor?.endDateTime),
        //     //         toDay: d.toLocaleString(),
        //     //         // title: item?.name ? !item.name.indexOf("internet") ? DATA_USER.given_name+ " ¡Tu factura está por vencer!" : item.name : '',
        //     //         // description: !item?.name.indexOf("internet") ? "Paga tu factura a unos cuantos clicks y con diferentes medios de pago. !Es fácil y sencillo!" :  item?.bucketBalance[0]?.remainingValueLabel ? item.bucketBalance[0].remainingValueLabel : '',
        //     //         // price: item?.price ? item.price : '',
        //     //         // validity: item?.bucketBalance[0]?.validFor ? item.bucketBalance[0].validFor?.endDateTime : '',
        //     //         // goTo: !item.name.indexOf("internet") ? 'Pagar factura' : item?.goTo ? item?.goTo : '',
        //     //         // toShow: new Date(item.bucketBalance[0].validFor?.endDateTime),
        //     //         // toDay: d.toLocaleString(),
        //     //     }
        //     // ))

        //     console.log("responseNoti.data.ConsumptionService", responseNoti.data.ConsumptionService)
        //     console.log("responseNoti dataFilterbucket:", dataArrayConsultaAll)
        // }
        // if (responseNoti.data?.InvoiceDetailCurrentPeriod) {
        //     let dataInvoiceDetailCurrentPeriod = responseNoti.data.InvoiceDetailCurrentPeriod
        //     console.log("item InvoiceDetailCurrentPeriod: ",  dataInvoiceDetailCurrentPeriod.billNo, dataInvoiceDetailCurrentPeriod.billingPeriod, dataInvoiceDetailCurrentPeriod.paymentDueDate, dataInvoiceDetailCurrentPeriod.remainingAmount)
        //     let endDate = dataInvoiceDetailCurrentPeriod.billingPeriod.endDateTime //'2022-05-21T00:00:00'
        //     let date1 = new Date(endDate);
        //     let date2 = new Date();
        //     let Difference_In_Time = date2.getTime() - date1.getTime();
        //     let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        //     console.log("Total number of days between dates  \n"+ endDate +"\n"+ date1 + "\n and \n" + date2 + " is: \n " + Difference_In_Days);
            
        //     if (dataInvoiceDetailCurrentPeriod?.remainingAmount?.value >= 0 && Difference_In_Days <= 5){

        //         dataArrayConsultaAll.push({
        //                 title: DATA_USER.given_name+ ", ¡Tu factura está por vencer!",
        //                 description: "Paga tu factura a unos cuantos clicks y con diferentes medios de pago. !Es fácil y sencillo!",
        //                 price: dataInvoiceDetailCurrentPeriod?.remainingAmount ? dataInvoiceDetailCurrentPeriod.remainingAmount.value : '',
        //                 validity: true,
        //                 goTo: 'Pagar factura',
        //                 urlGoTo: 'https://miclaro.com.ec/pagos/',
        //                 toShow: true,
        //                 toDay: date2.toLocaleString(),
        //             })
        //     }
        //     // console.log("responseNoti.data.ConsumptionService", responseNoti.data.InvoiceDetailCurrentPeriod)
        //     console.log("responseNoti dataInvoiceDetailCurrentPeriod:", dataInvoiceDetailCurrentPeriod)
        // }
        // if (responseNoti.data?.BillingPeriods) {
        //     let dataBillingPeriods = JSON.parse(responseNoti.data.BillingPeriods)

        //     dataBillingPeriods.map((item, index) => {
        //         return dataArrayConsultaAll.push({
        //             title: item?.billNo ? 'Factura: ' + item.billNo : '',
        //             description: item?.state ? item.state : '',
        //             // price: item?.price ? item?.state === 'Pagado' ? '0' : item.price : '',
        //             price: item?.state === 'Pagado' ? '0' : item?.amountDue ? item.amountDue?.value : '',
        //             validity: item?.billingPeriod ? item.billingPeriod?.endDateTime : '',
        //             goTo: item?.goTo ? item.goTo : '',
        //         })

        //     })

        //     console.log("responseNoti dataFilterbucket GetBillingPeriods:", dataBillingPeriods)
        // }

        // if (responseNoti.data?.CuentasAsociadasHomeMiClaro) {
        //     let dataCuentasAsociadasHomeMiClaro = JSON.parse(responseNoti.data.CuentasAsociadasHomeMiClaro)

        //     dataCuentasAsociadasHomeMiClaro.map((item, index) => {
        //         return dataArrayConsultaAll.push({
        //             title: item?.billNo ? 'Factura: ' + item.billNo : '',
        //             description: item?.state ? item.state : '',
        //             // price: item?.price ? item?.state === 'Pagado' ? '0' : item.price : '',
        //             price: item?.state === 'Pagado' ? '0' : item?.amountDue ? item.amountDue?.value : '',
        //             validity: item?.billingPeriod ? item.billingPeriod?.endDateTime : '',
        //             goTo: item?.goTo ? item.goTo : '',
        //         })

        //     })

        //     console.log("responseNoti dataFilterbucket GetCuentasAsociadasHomeMiClaro:", dataCuentasAsociadasHomeMiClaro)
        // }
        // dataArrayConsultaAll = dataArrayConsultaAll.filter(item => (!item.title.indexOf('internet') || !item.title.indexOf('Carolina') ))
        // dataArrayConsultaAll = dataArrayConsultaAll.filter(item => (!item.title.indexOf('factura') ))

        // localStorage.setItem('Consulta_de_Saldo', JSON.stringify(dataArrayConsultaAll))
        // getDataConsultaSaldo(dataArrayConsultaAll)
        // $("#c01LoginFloatDiv").show()

        if(dataRespose){
            return dataRespose;
        }else{
            return null
        }
    }
    function WriteCookie() {
        let Token = getCookie('authorization_sso')
        var now = new Date();
        now.setMinutes(now.getMinutes() + 20 )

        document.cookie = 'LoginUser='+Token+';expires='+now.toUTCString()+';path=/';

        //     let Token = sessionStorage.getItem('AUTHORIZATION_TOKEN')
        //     var now = new Date();
        //     var minutes = 3;
            
        //     now.setTime(now.getTime() + (minutes, 60, 1000));
        //     let cookievalue = Token.toString()
        //     let Domain = "Domain =" + window.location.host;
        //     console.log("WriteCookie", now, cookievalue, Domain)
        //     document.cookie = 'LoginUser='+cookievalue+';expires='+now.toUTCString()+';path=/';
    }
    async function getUserInfo(){
        let authorization_sso = getCookie('authorization_sso')
        // let authorization_sso = sessionStorage.getItem('authorization_sso')
        // let authorization_sso = document.cookie.split(";").filter(cookie => cookie.includes("authorization_sso")).split("=")[1];
        console.log("eltokennnn", authorization_sso)
        let dataLogin = 'token=' + authorization_sso
        let url_servicio_web = urlServicioWeb
        await $.ajax({
            url: url_servicio_web+'login_automatic?' + dataLogin,
            type: "GET",
            // data: jsonSendData,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: async function (data) {
                console.log("response data login_automatic: ", JSON.stringify(data));
                //set css error from a type response

                let token = data.access_token
                let code = data.code
                var now = new Date();
                now.setMinutes(now.getMinutes() + 20 )
                sessionStorage.setItem("DATA_USER", JSON.stringify(data))
                localStorage.setItem("authorization_sso", token)
                localStorage.setItem("id_token", data?.id_token)
                localStorage.setItem("authorization_code", code)
                console.log('authorization_sso='+token+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000","").replace("www.","")+';path=/')
                
                document.cookie = 'authorization_sso='+token+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000","").replace("www.","")+';path=/; secure; samesite=None';
                document.cookie = 'id_token='+getCookie("id_token")+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';
                document.cookie = 'authorization_code='+getCookie("authorization_code")+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/ ; secure; samesite=None';
                document.cookie = 'DATA_USER='+encodeURIComponent(JSON.stringify(data))+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';


                clearTimeout(inactivityTimeout);
                    const newTimeout = setTimeout(() => {
                        console.log("actualizo")
                    location.reload()
                }, inactivityThreshold);
    inactivityTimeout = newTimeout;
                $("#fieldusername").addClass('success');
                $("#fieldpassword").addClass('success');
                muestraLogin("show")
                hideAndReplaceComponets(data)
                WriteCookie()
                notificacionHidden === "false" ? getAssociatedAccounts(data) : null
                //if(location.host == "backoffice-ii-ng.amx-dev.clarodigital.net") {
                    $.ajax({
                        url: url_servicio_web+'notificaciones',
                        type: "POST",
                        data: getCookie('authorization_sso'),
                        dataType: "json",
                        contentType: "application/json; charset=utf-8",
                        success: function (data2) {
                            console.log(data2.MessagesList,"loultimo")
                                setDataConsultaSaldo(data2.MessagesList)
                                // setRandomItem(data2.MesagesList)
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                console.log(thrownError);
                                console.log(xhr.status, xhr.responseJSON, xhr);
                            } 
                        });
                //}
                // WriteConsulta_de_Saldo()
                // inputusername.prop("disabled", false)
                // inputpassword.prop("disabled", false)
                // btnLogin.prop("disabled", false)
            },
            error: function (xhr, ajaxOptions, thrownError) {

                // inputusername.prop("disabled", false)
                // inputpassword.prop("disabled", false)
                // btnLogin.prop("disabled", false)
                errorSetDataStyleLogin(xhr)
                muestraLogin("error")
                clearSessionStorage()
                console.log(thrownError);
                console.log(xhr.status, xhr.responseJSON, xhr);
            }
        });
    }
    function sendLoginAppReact(inputusername, inputpassword, btnLogin) {
        // let dataLogin = 'username=' + username + '&password=' + password
        let url_servicio_web = urlServicioWeb
        var SendDatalogin = {
            username: username,
            password: password
        };
        var jsonSendData = JSON.stringify(SendDatalogin);
        $.ajax({
            url: url_servicio_web+'login',
            type: "POST",
            data: jsonSendData,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            success: async function (data) {
                console.log("response data: ", JSON.stringify(data));
                //set css error from a type response

                let token = data.access_token
                let code = data.code
                let id_token = data?.id_token || ""

                sessionStorage.setItem("DATA_USER", JSON.stringify(data))
                var now = new Date();
                now.setMinutes(now.getMinutes() + 20 )
                localStorage.setItem("authorization_sso", token)
                localStorage.setItem("id_token", data?.id_token)
                localStorage.setItem("authorization_code", code)

                console.log('authorization_sso='+token+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000","").replace("www.","")+';path=/')

                // document.cookie = "authorization_sso=" + token + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";
                // document.cookie = "id_token=" + data.id_token + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";
                // document.cookie = "authorization_code=" + code + "; max-age=900; domain=" + window.location.host.replace('www.', '') + "; path=/";

                document.cookie = 'authorization_sso='+token+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';
                document.cookie = 'id_token='+data?.id_token+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';
                document.cookie = 'authorization_code='+code+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';
                document.cookie = 'DATA_USER='+encodeURIComponent(JSON.stringify(data))+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';
                console.log("eltokennnn", document.cookie)

                clearTimeout(inactivityTimeout);
    const newTimeout = setTimeout(() => {
        console.log("actualizo")
      location.reload()
    }, inactivityThreshold);
    inactivityTimeout = newTimeout;

                $("#fieldusername").addClass('success');
                $("#fieldpassword").addClass('success');
                muestraLogin("show")
                hideAndReplaceComponets(data)
                WriteCookie()
                notificacionHidden === "false" ? getAssociatedAccounts(data) : null
                // WriteConsulta_de_Saldo()
                inputusername.prop("disabled", false)
                inputpassword.prop("disabled", false)
                btnLogin.prop("disabled", false)

                //if(location.host == "backoffice-ii-ng.amx-dev.clarodigital.net") {
                    $.ajax({
                        url: url_servicio_web+'notificaciones',
                        type: "POST",
                        data: token,
                        dataType: "json",
                        contentType: "application/json; charset=utf-8",
                        success: function (data) {
                            console.log(data.MessagesList,"loultimo")
                                setDataConsultaSaldo(data.MessagesList)
                                // setRandomItem(data.MesagesList)
                            },
                            error: function (xhr, ajaxOptions, thrownError) {
                                console.log(thrownError);
                                console.log(xhr.status, xhr.responseJSON, xhr);
                            } 
                        });
                //}
            },
            error: function (xhr, ajaxOptions, thrownError) {

                inputusername.prop("disabled", false)
                inputpassword.prop("disabled", false)
                btnLogin.prop("disabled", false)
                muestraLogin("hide")
                errorSetDataStyleLogin(xhr)
                clearSessionStorage()
                console.log(thrownError);
                console.log(xhr.status, xhr.responseJSON, xhr);
            }
        });
    }
    function errorSetDataStyleLogin(msgError = false) {
        let msg = 'Ha ocurrido un error, vuelva a intentarlo'

        if (msgError) {
            console.log("msgError: ", msgError, msgError?.responseText)
            let responseJSONError = JSON.parse(msgError?.responseText.replaceAll("'", '"') )
            console.log("msgError?.responseText: ", responseJSONError, responseJSONError?.error_description)
            let error_Desc = ""
            if (responseJSONError?.error_description) {
                error_Desc = responseJSONError?.error_description.indexOf("&") !== -1 ? responseJSONError?.error_description.split("&")[1] : responseJSONError?.error_description
            } else {
                error_Desc = responseJSONError?.error
            }
            error_Desc = error_Desc?.length ? error_Desc.indexOf("=") !== -1 ? error_Desc.split("=")[1] : error_Desc : "Ha ocurrido un error, vuelva a intentarlo" ;
            msg = error_Desc
            if (error_Desc === "User_not_registered_in_SSO") {
                msg = "El usuario no esta registrado";
            }
            if (error_Desc === "Password_not_valid") {
                msg = "La contraseña que ingresaste es incorrecta";
            }
            if (error_Desc === "Too+many+login+atempts%2C+wait+for%3A+15+minutes") {
                msg = "Ha excedido el máximo de intentos permitidos. Su cuenta ha sido bloqueada temporalmente por 15 minutos, inténtelo más tarde.";
            }
            if (error_Desc === "Too+many+login+atempts%2C+wait+for%3A+1+hour") {
                msg = "Ha excedido el máximo de intentos permitidos. Su cuenta ha sido bloqueada temporalmente por 1 hora, inténtelo más tarde.";
            }
            if (error_Desc === "Too+many+login+atempts%2C+wait+for%3A+12+hours") {
                msg = "Ha excedido el máximo de intentos permitidos. Su cuenta ha sido bloqueada temporalmente por 12 horas, inténtelo más tarde.";
            }
            if (msg === "Session Not Found") {
                msg = "Usuario no encontrado";
            }
            if (msg === "Internal Server Error") {
                msg = 'Ha ocurrido un error, vuelva a intentarlo'
            }
            console.log("msgError:", msg, responseJSONError?.error_description)
        }

        $("#errorMsgLogin").text(msg)
        $("#errorMsgLogin").show()
        $("#fieldusername").addClass('error');
        $("#fieldpassword").addClass('error');
        console.log("errorSetDataStyleLogin:> ", msg)
    }
    function clearSessionStorage() {
        var now = new Date();
        now.setMinutes(now.getMinutes() - 20 )
        console.log("clearSessionStorage____ REACT")
        localStorage.removeItem("authorization_token")
        localStorage.removeItem("DATA_AUTH_REQUEST_ID")
        localStorage.removeItem("DATA_AUTH_ASSERTON_ID")
        localStorage.removeItem("authorization_code")
        sessionStorage.removeItem("DATA_USER")
        // localStorage.removeItem('authorization_sso');
        localStorage.removeItem('Consulta_de_Saldo');

        document.cookie = 'authorization_sso=prueba'+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000","").replace("www.","")+';path=/; secure; samesite=None';
        document.cookie = 'id_token=prueb'+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';
        document.cookie = 'authorization_code='+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/ ; secure; samesite=None';
        document.cookie = 'DATA_USER=prueba'+';expires='+now.toUTCString()+';domain='+window.location.host.replace(":3000", "").replace("www.", "")+';path=/; secure; samesite=None';
    }
    function muestraLogin(accion){
        if(accion === 'show') {
            $('#miClaroLiga').hide();
            $('#miClaroOpciones').show();
        } else {
            $('#miClaroLiga').show();
            $('#miClaroOpciones').hide();
        } 
    }
    function clearStatusErrorAppReact() {
        $("#fieldpassword").removeClass('error');
        $("#fieldpassword").removeClass('success');
        $("#fieldusername").removeClass('error');
        $("#fieldusername").removeClass('success');
        $("#errorMsgLogin").hide();
    }
    function hideAndReplaceComponets(data) {
        console.log("hideAndReplaceComponets React function", data)
        $("#bloqueUser").show()
        let welcomeUsername = '<p class="textAtom blanco text3Xl textAtomBold title" id="welcomeUsername"> Hola ' + data?.given_name + '</p>';
        $("#sliderItemContTitle").prepend(welcomeUsername);
        $("#contentNameUser").text(data?.given_name)
        $("#cp01BarClient").hide()
        $("#c01LoginFormReactPR").hide()
        $("#c01LoginFloatDiv").show()
        console.log("c01LoginFormReactPR hideAndReplaceComponets")
    }


    function onChange(e) {
        clearStatusErrorAppReact(e)
        let target = e.target
        // console.log("onChange", target, e)
        if (target.name === 'password') {
            // && this.state.errorMessage !== ''
            // this.setState(
            // 	{ errorMessage: ''}
            // )
            let cleanPwd = target.value.replace(/[ /",ñ]/gi, '');
            setPassWord(target.value)
        }
        if (target.name === 'username') {
            //let cleanUser = target.value.replace(/[^a-zA-Z0-9\s]/gi, '');
            // this.setState({emailInvalid: false})
            setUserName(target.value)
        }

        // this.setState({
        // 	[target.name] : target.value
        // });
    }

    // console.log("DataConsultaSaldo: ", randomItem, dataConsultaSaldo?.bucket)
    // let ConsultaDeSaldo = localStorage.getItem('Consulta_de_Saldo') ? JSON.parse(localStorage.getItem('Consulta_de_Saldo')) : null;
    // let authorizationSSO = localStorage.getItem('authorization_sso') ? localStorage.getItem('authorization_sso') : null;
    // ConsultaDeSaldo = localStorage.getItem('Consulta_de_Saldo') === "[]" ? null : ConsultaDeSaldo
    // console.log("ConsultaDeSaldo var", ConsultaDeSaldo)
    console.log(randomItem, "booleano")
    return (
        <>
            <section className="c01LoginWrap" id="c01LoginFormReactPR">
                <div className="c01Login">
                    <div className="c01LoginCont">
                        <div className="c01LoginForm">
                            <h3 className="h4">
                                ¿Eres cliente Claro? <span> Ingresa con tus datos y ve ofertas exclusivas para tí</span>
                            </h3>
                            {/* <C01LoginPeopleOrgForm
                            onSubmit={onSubmitForm}
                        /> */}
                            <form action="#" onSubmit={(e) => onSubmitLoginAppReact(e)} name="formLoginReactPR">
                                <div className="c01LoginFormLeft">
                                    <fieldset>
                                        <input className="" id="rut" name="username" type="text" placeholder="Usuario" value={username}
                                            maxLength="98" autoComplete="off" step="" required
                                            onChange={(e) => onChange(e)} />
                                        <span className="errorField light" id="errorMsgLogin" style={{ display: "none" }} ></span>
                                    </fieldset>
                                    <fieldset className='c01LoginFormLeftPass'>
                                        <input className="" name="password" type="password" placeholder="contrase&ntilde;a" value={password}
                                            maxLength="12" minLength="8" autoComplete="off" required
                                            onChange={(e) => onChange(e)} ref={passwordRef} />
                                            <i className='ico-eye' onClick={handleShowPass} ref={eyeIcoRef}></i>
                                    </fieldset>
                                </div>
                                <div className="c01LoginFormRight">
                                    <fieldset>
                                        <button className="btn btnTransRojo8" type="submit" id="btnLogin">Iniciar Sesi&oacute;n</button>
                                    </fieldset>
                                    <p>
                                        <strong>¿No tienes cuenta? </strong>
                                        <a className="textLink" href="https://miclaro.claropr.com/register" target="_blank" rel="noreferrer">Crear nueva cuenta</a>
                                    </p>
                                </div>
                            </form>
                        </div>
                        {/* <div className="c01LoginFloat wow">

                        </div> */}
                    </div>
                </div>
            </section>
            <section className={`c01Noty ${classProps}`}>
                <div className="c01Login">
                    <div className="c01LoginCont">
                        <div className="c01LoginForm">
                            
                        </div>
                        {
                            randomItem?.subject
                                ?
                                    <div className="c01LoginFloat wow" id="c01LoginFloatDiv" style={{top:"-163px"}}>
                                        <h3 className="h5">{randomItem?.subject}</h3>
                                        <div className="c01LoginFloatText">
                                            <div className='c01LFTCont'>
                                                {
                                                    randomItem?.message &&
                                                    <p className='price' style={{fontSize: "1rem"}}>
                                                        {randomItem?.message}
                                                    </p>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                
                                :
                                <>
                                {
                                    displayDefaultNoti === "false"
                                    ?
                                    <div className="c01LoginFloat wow" id="c01LoginFloatDiv">
                                        <h3 className="h5">{title}</h3>
                                        <div className="c01LoginFloatText">
                                            <div className='c01LFTCont'>
                                                {
                                                    description &&
                                                    <p>
                                                        {description}
                                                    </p>
                                                }
                                                {
                                                    price &&
                                                    <p className='price'>
                                                        {price}
                                                    </p>
                                                }
                                                {
                                                    validity &&
                                                    <p className='val'>
                                                        {validity}

                                                    </p>
                                                }

                                            </div>
                                            <div className="c01LFTbtn">
                                                <a className="btn btnTransBlancoNegro" href={urlGoTo} target={targetGoTo !== "" ? targetGoTo : "_self"}>
                                                    {goTo}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                </>
                                
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

C01Notification.defaultProps = {
    title: '¿Nos visitas de una Empresa o Pyme?',
    description: 'Descubre los servicios y promociones que preparamos para tu negocio',
    price: '',
    validity: '',
    // price: '$5,609.90',
    // validity: 'Vence 13 / Abril / 2020',
    goTo: 'Ir a Empresas',
    urlGoTo: '#!',
    targetGoTo: '_blank',
    classProps: '',
}

C01Notification.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
    validity: PropTypes.string,
    goTo: PropTypes.string,
    urlGoTo: PropTypes.string,
    targetGoTo: PropTypes.string,
    classProps: PropTypes.string,
}

export default C01Notification
